console.log('loaded stripe/index.js')
document.addEventListener('turbolinks:load', function () {
    // debugger;
    var div = document.getElementById('payment')
    if (div === null) {
        console.log('missing div element');
        return;
    }

    stripe_public_key = document.querySelector('meta[name="stripe-public-key"]').getAttribute('content')
    stripe = Stripe(stripe_public_key)
    stripe.redirectToCheckout({
        sessionId: div.getAttribute('data-session-id')
    }).then(function (results) {

    })
})